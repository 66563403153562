import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        currentNav:'',
        username:'',
        oldpassword:'',
        loggedInUser:{
            name:'User',
            isAdmin:false
        },
        loggedIn:false,

        showClasses:true,

        programList:[
            {
                id:1,
                name:'Theory'
            },
            {
                id:2,
                name:'Road',
            },
            {
                id:3,
                name:'Range'
            }
        ],

        weekList:[
            {
                id:1,
                name:'Week 1'
            },
            {
                id:2,
                name:'Week 2',
            },
            {
                id:3,
                name:'Week 3'
            },
            {
                id:4,
                name:'Week 4'
            }
        ],
        
        dateResults:[],
        dateResults_s:[],
        timeResults:[],
        timeResults_s:[],

        resourceResults:[],
        instructorResults:[],
        studentResults:[
            {
                id:1,
                name:'Joe Shmo',
                selected:false,
                classList:[
                    {
                        id:1,
                        name:'Backing',
                        selected:false
                    },
                    {
                        id:2,
                        name:'Right Turns',
                        selected:false
                    }
                ]
            },
            {
                id:2,
                name:'Jane Doe',
                selected:false,
                classList:[
                    {
                        id:1,
                        name:'Backing',
                        selected:false
                    },
                    {
                        id:2,
                        name:'Right Turns',
                        selected:false
                    },
                    {
                        id:3,
                        name:'Left Turns',
                        selected:false
                    }
                ]
            },
            {
                id:3,
                name:'Sam Smith',
                selected:false,
                classList:[
                    {
                        id:1,
                        name:'Backing',
                        selected:false
                    },
                    {
                        id:2,
                        name:'Right Turns',
                        selected:false
                    }
                ]
            },
            {
                id:4,
                name:'Peter Parker',
                selected:false,
                classList:[
                    {
                        id:1,
                        name:'Backing',
                        selected:false
                    },
                    {
                        id:3,
                        name:'Left Turns',
                        selected:false
                    }
                ]
            }
        ],
        
        

        //road
        timeResults_road:[
            {
                id:1,
                name:'9-11'
            },
            {
                id:2,
                name:'11-1'
            },
            {
                id:3,
                name:'1-3'
            },
            {
                id:4,
                name:'3-5'
            },
            {
                id:5,
                name:'5-7'
            },
         
        ],
        timeResults_sroad:[
            {
                id:1,
                name:'6-8'
            },
            {
                id:2,
                name:'8-10'
            },
            {
                id:3,
                name:'10-12'
            },
            {
                id:4,
                name:'1-3'
            },
            {
                id:5,
                name:'3-5'
            },
         
        ],
        resourceResults_road:[
            {
                id:1,
                name:'Truck 1',
                class:'resource1'
            },
            {
                id:2,
                name:'Truck 2',
                class:'resource2'
            },
            {
                id:3,
                name:'Truck 3',
                class:'resource3'
            }
        ],
        instructorResults_road:[
            {
                id:1,
                name:'Adam Ant'
            },
            {
                id:2,
                name:'Bill Barnes'
            },
            {
                id:3,
                name:'Chris Cringle'
            }
        ],


        //theory
        timeResults_theory:[
            {
                id:1,
                name:'9-5'
            }
            
         
        ],
        timeResults_stheory:[
            {
                id:1,
                name:'6-3'
            }
            
         
        ],
        resourceResults_theory:[
            {
                id:1,
                name:'Classroom 1',
                class:'resource1'
            },
           
        ],
        instructorResults_theory:[
            {
                id:1,
                name:'Dan Douglas'
            },
            {
                id:2,
                name:'Fred Fun'
            },
            {
                id:3,
                name:'George Galvin'
            }
        ],


        //weeks
        dateResults_1:[
            {
                id:1,
                date:'3/14/2022',
                name:'Mon 3/14'
            },
            {
                id:2,
                date:'3/15/2022',
                name:'Tue 3/15'
            },
            {
                id:3,
                date:'3/16/2022',
                name:'Wed 3/16'
            },
            {
                id:4,
                date:'3/17/2022',
                name:'Thu 3/17'
            },
            {
                id:5,
                date:'3/18/2022',
                name:'Fri 3/18'
            },
           
        ],
        dateResults_2:[
            {
                id:1,
                date:'3/21/2022',
                name:'Mon 3/21'
            },
            {
                id:2,
                date:'3/22/2022',
                name:'Tue 3/22'
            },
            {
                id:3,
                date:'3/23/2022',
                name:'Wed 3/23'
            },
            {
                id:4,
                date:'3/24/2022',
                name:'Thu 3/24'
            },
            {
                id:5,
                date:'3/25/2022',
                name:'Fri 3/25'
            },
            
        ],
        dateResults_3:[
            {
                id:1,
                date:'3/28/2022',
                name:'Mon 3/28'
            },
            {
                id:2,
                date:'3/29/2022',
                name:'Tue 3/29'
            },
            {
                id:3,
                date:'3/30/2022',
                name:'Wed 3/30'
            },
            {
                id:4,
                date:'3/31/2022',
                name:'Thu 3/31'
            },
            {
                id:5,
                date:'4/1/2022',
                name:'Fri 4/1'
            },
            
        ],
        dateResults_4:[
            {
                id:1,
                date:'4/4/2022',
                name:'Mon 4/4'
            },
            {
                id:2,
                date:'4/5/2022',
                name:'Tue 4/5'
            },
            {
                id:3,
                date:'4/6/2022',
                name:'Wed 4/6'
            },
            {
                id:4,
                date:'4/7/2022',
                name:'Thu 4/7'
            },
            {
                id:5,
                date:'4/8/2022',
                name:'Fri 4/8'
            },
            
        ],
         
        //satu
        dateResults_s1:[
            
            {
                id:6,
                date:'3/19/2022',
                name:'Sat 3/19'
            },
        ],
        dateResults_s2:[
            
            {
                id:6,
                date:'3/26/2022',
                name:'Sat 3/26'
            },
            
        ],
        dateResults_s3:[
            
            {
                id:6,
                date:'4/2/2022',
                name:'Sat 4/2'
            },
        ],
        dateResults_s4:[
            
            {
                id:6,
                date:'4/9/2022',
                name:'Sat 4/9'
            },
        ],
        
        
        timeItems:[
            {
                id:100,
                studentId:1,
                studentName:'Joe Shmo',
                resourceId:1,
                resourceName:'Truck 1',
                instructorId:1,
                instructorName:'Adam Ant',
                date:'3/14/2022',
                time:'9-11',
                classId:1,
                className:'Backing'
            },
            {
                id:101,
                studentId:2,
                studentName:'Jane Doe',
                resourceId:1,
                resourceName:'Truck 1',
                instructorId:1,
                instructorName:'Adam Ant',
                date:'3/14/2022',
                time:'9-11',
                classId:2,
                className:'Right Turns'
            },
            {
                id:102,
                studentId:2,
                studentName:'Jane Doe',
                resourceId:2,
                resourceName:'Truck 2',
                instructorId:2,
                instructorName:'Bill Barnes',
                date:'3/14/2022',
                time:'9-11',
                classId:2,
                className:'Right Turns'
            },
            {
                id:103,
                studentId:2,
                studentName:'Jane Doe',
                resourceId:2,
                resourceName:'Truck 2',
                instructorId:1,
                instructorName:'Adam Ant',
                date:'3/15/2022',
                time:'11-1',
                classId:3,
                className:'Left Turns'
            },
            {
                id:104,
                studentId:2,
                studentName:'Jane Doe',
                resourceId:2,
                resourceName:'Truck 2',
                instructorId:1,
                instructorName:'Adam Ant',
                date:'3/24/2022',
                time:'1-3',
                classId:3,
                className:'Left Turns'
            },
            {
                id:104,
                studentId:2,
                studentName:'Jane Doe',
                resourceId:1,
                resourceName:'Classroom 1',
                instructorId:1,
                instructorName:'Adam Ant',
                date:'3/15/2022',
                time:'9-5',
                classId:3,
                className:'Left Turns'
            }
        ]
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
        
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})