<template>
  <div class="maingrid">
      <div class="filter">
          <div class="horizFlexStretch">
              <div>
                  <smallbutton v-for="w in weekList" :key="w.id" :value="w.id" :toplabel="w.name" 
                        :buttonclass="weekbuttonclass(w.id)" @click="setWeek(w.id)"/>
              </div>
              <div>
                <smallbutton v-for="p in programList" :key="p.id" :value="p.id" :toplabel="p.name" 
                        :buttonclass="programbuttonclass(p.id)" @click="setProgram(p.id)"/>
              </div>
          </div>
          

          
      </div>
        <div class="selector">
            <label for="" class="selectorTitle">Students</label>
            <div v-for="result in studentResults" :key="result.id" :value="result.id" >
                    <lookupresult :topLeft="result.name" @click="selectStudent(result)"
                        :selected="isSelected(result)">
                    
                    </lookupresult>  
                </div>

                <br>
                <hr>
                <br>

                <label for="" class="selectorTitle">Instructors</label>
                <div v-for="result in instructorResults" :key="result.id" :value="result.id" >
                    <lookupresult :topLeft="result.name" @click="selectInstructor(result)"
                        :selected="isSelected_Instructor(result)">
                    
                    </lookupresult>  
                </div>

                <br>
                <hr>
                <br>

                <label for="" class="selectorTitle">Resources</label>
                <div v-for="result in resourceResults" :key="result.id" :value="result.id" >
                    <lookupresult :topLeft="result.name" @click="selectResource(result)" :addedClass="result.class"
                        :selected="isSelected_Resource(result)" >
                    
                    </lookupresult>  
                </div>
        </div>


        <!-- WEEK -->
        <div class="calendar" >
            <div class="calendarrow">
                <!-- empty corner -->
                <div></div>

                <!-- date headers -->
                <div v-for="dt in dateResults" :key="dt.id" :value="dt.id" :class="cheaderclass(dt)">{{dt.name}}</div>
            </div>
          

            <!-- time headers -->
            <div v-for="ti in timeResults" :key="ti.id" :value="ti.id"  class="calendarrow">
                <div  class="cheader">{{ti.name}}</div>  
                <!-- {{ti.name}} -->
                <!-- uncomment this when we are ready to send in data -->
                <div v-for="dt in dateResults" :key="dt.id" :value="dt.id" class="citem">
                    <schedulebox :selDate="dt.date" :selTime="ti" @refresh="refresh" :timeResults="timeResults" />
                </div>
                
            </div>
      </div>

      <div class="calendar2" >
            <div class="calendarrow2">
                <!-- empty corner -->
                <div></div>

                <!-- date headers -->
                <div v-for="dt in dateResults_s" :key="dt.id" :value="dt.id" :class="cheaderclass(dt)">{{dt.name}}</div>
            </div>
          

            <!-- time headers -->
            <div v-for="ti in timeResults_s" :key="ti.id" :value="ti.id"  class="calendarrow2">
                <div  class="cheader">{{ti.name}}</div>  
                <!-- {{ti.name}} -->
                <!-- uncomment this when we are ready to send in data -->
                <div v-for="dt in dateResults_s" :key="dt.id" :value="dt.id" class="citem">
                    <schedulebox :selDate="dt.date" :selTime="ti" @refresh="refresh" :timeResults="timeResults_s" />
                </div>
                
            </div>
      </div>

        
  </div>
</template>

<script>
import smallbutton from '../controls/button.vue'
import lookupresult from '../controls/lookupresult.vue'

import schedulebox from  '../actions/schedulebox.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:'scheduler',
    components:{
        smallbutton,
        lookupresult,
        schedulebox
    },
    computed:{
        ...mapFields([
            'programList',
            'weekList',
            'dateResults',
            'timeResults',
            'studentResults',
            'instructorResults',
            'resourceResults',
            'showClasses',

            'resourceResults_theory',
            'resourceResults_road',
            'timeResults_theory',
            'timeResults_road',
            'instructorResults_theory',
            'instructorResults_road',
            'dateResults_1',
            'dateResults_2',
            'dateResults_3',
            'dateResults_4',
            'dateResults_s',
            'timeResults_s',
            'dateResults_s1',
            'dateResults_s2',
            'dateResults_s3',
            'dateResults_s4',
            'timeResults_stheory',
            'timeResults_sroad',

            
        ]),
    },
    data(){
        return{
            paramTrue:true,

            selectedProgram:1,
            selectedWeek:1,

            selectedStudent:{
                id:-1,
                name:'Joe Shmo'
            },
            selectedResource:{
                id:-1,
                name:'Red Truck'
            },
            selectedInstructor:{
                id:-1,
                name:'Bill Barnes'
            },

            
        }
    },
    methods:{
        refresh(){
            //go get new data or somethign
        },
        setCalendarView(view){
            this.selectedView = view
        },
        setProgram(program){
            this.selectedProgram=program
            switch (program) {
                case 1:
                    this.resourceResults=this.resourceResults_theory
                    this.timeResults=this.timeResults_theory
                    this.instructorResults=this.instructorResults_theory
                    this.timeResults_s=this.timeResults_stheory
                    this.showClasses=false
                    break;
            
                default:
                    this.resourceResults=this.resourceResults_road
                    this.timeResults=this.timeResults_road
                    this.instructorResults=this.instructorResults_road
                    this.timeResults_s=this.timeResults_sroad
                    this.showClasses=true
                    break;
            }
            
            this.updateCounts()
        },
        setWeek(week){
            this.selectedWeek=week
            switch (week) {
                case 1:
                    this.dateResults=this.dateResults_1
                    this.dateResults_s=this.dateResults_s1
                    break;
                case 2:
                    this.dateResults=this.dateResults_2
                    this.dateResults_s=this.dateResults_s2
                    break;
                case 3:
                    this.dateResults=this.dateResults_3
                    this.dateResults_s=this.dateResults_s3
                    break;
                case 4:
                    this.dateResults=this.dateResults_4
                    this.dateResults_s=this.dateResults_s4
                    break;
                default:
                    break;
            }
        },
        programbuttonclass(program){
            if(program==this.selectedProgram){return "filterbutton buttonhighlight"}
            else{return "filterbutton buttonprimary"}
        },
        weekbuttonclass(week){
            if(week==this.selectedWeek){return "filterbutton buttonhighlight"}
            else{return "filterbutton buttonprimary"}
        },
        isSelected(result){
            return result.id==this.selectedStudent.id
        },
        isSelected_Instructor(result){
            return result.id==this.selectedInstructor.id
        },
        isSelected_Resource(result){
            return result.id==this.selectedResource.id
        },
        selectStudent(result){
            this.selectedStudent=result
        },
        selectInstructor(result){
            this.selectedInstructor=result
        },
        selectResource(result){
            this.selectedResource=result
        },
        
        cheaderclass(dt){
           // if(dt.id==1){return "cheader_completed"}
           // if(dt.id==2){return "cheader_started"}
           // else{return "cheader"}
           return "cheader"
        },
        cheaderclass_course(dt){
            /* if(dt.id==1){return "cheader_completed"}
            if(dt.id==2){return "cheader_started"} */
            return "cheader"
        },
        
        getData(){
            this.setProgram(2)
            this.setWeek(1)
            this.updateCounts()
        },
        updateCounts(){
            document.body.style.setProperty('--colcount',this.dateResults.length);
            document.body.style.setProperty('--rowcount',this.timeResults.length);
            document.body.style.setProperty('--colcount2',this.dateResults_s.length);
            document.body.style.setProperty('--rowcount2',this.timeResults_s.length);
        }
    },
    mounted(){
        this.getData();
    }
}
</script>

<style>
:root{
    --colcount:6;
    --rowcount:5;
    --colcount2:1;
    --rowcount2:5;
  
}
.filterbutton{
    border: none;
    border-radius:5px;
    width:120px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px 5px 10px 5px;
  text-decoration: none;
}
.maingrid{
        height:100%;
        display:grid;
        grid-template-columns:1fr 5fr 1fr;
        grid-template-rows:80px auto;
        grid-template-areas: 
            "space filter filter"
            "selector calendar calendar2";
}
.calendar{
    grid-area:calendar;
    border:1px solid darkgray;

    display:grid;
    
    grid-template-rows:20px repeat(var(--rowcount),1fr);
    
}
.calendar2{
    grid-area:calendar2;
    border:1px solid darkgray;

    display:grid;
    
    grid-template-rows:20px repeat(var(--rowcount2),1fr);
    
}
.calendarrow{
    display: grid;
    grid-template-columns:20px repeat(var(--colcount), 1fr);
}
.calendarrow2{
    display: grid;
    grid-template-columns:20px repeat(var(--colcount2), 1fr);
}
.filter{
    grid-area: filter;
    /* border:1px solid darkgray; */
}
.selector{
    grid-area: selector;
    /* border:1px solid darkgray; */
    padding:10px;
}
.selectorTitle{
    font-size:x-large;
}

.cheader{
    background-color:lightgray;
}
.rheader{
    background-color:lightgray;
   grid-column: 1;
}
.vert{
     /* transform: rotate(-90deg);  */
    writing-mode:sideways-lr;
    
    
}
.cheader_started{
    background-color:rgb(255, 255, 151);
}
.cheader_completed{
    background-color:rgb(177, 212, 177);
}
.citem{
    border:1px solid lightgray;
    font-size:small;
}
.resource1{
    border-left: solid blue 3px;
    border-bottom:solid lightgray 1px;
}
.resource2{
    border-left: solid red 3px;
    border-bottom:solid lightgray 1px;
}
.resource3{
    border-left: solid green 3px;
   border-bottom:solid lightgray 1px;
}
.resource4{
    border-left: solid purple 3px;
   border-bottom:solid lightgray 1px;
}
.resource5{
    border-left: solid orange 3px;
   border-bottom:solid lightgray 1px;
}
</style>