<template>
  <div class="outerDiv" :style="configStyle">
      <svheader class="header"/>
      <scheduler class="main"/>
      <!-- <svfooter class="footer"/> -->
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../services/datamethods.js'
import format from '../style/format.js'
import style from '../style/style.js'
import svheader from './header.vue'
import svfooter from './footer.vue'
import scheduler from './screens/scheduler.vue'
export default {
    name:'Start',
    components:{
        svheader,
        svfooter,
        scheduler
    },
    mixins:[datamethods, format,style],
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
 
        ]),
        
    },
    data(){
        return{
            
        }
    },
    methods:{
        
    },
    mounted(){
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
        if(tmp!=null){
            this.username = tmp;
        }


        this.setColors();
        
    },
    
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
/* full screen */
@media screen and (min-width: 812px) {
    .outerDiv{
 
        height:98vh;
        display:grid;
        grid-template-rows:55px auto;
        grid-template-areas: 
            "header"
            "center";
    }
    .entry{
        flex-direction: row;
    }

    
}
/* phone */
@media screen and (max-width: 812px) {
    .outerDiv{
        height:98vh;
        display:grid;
        grid-template-rows:80px auto;
        grid-template-areas: 
            "header"
            "center";
    }
    .entry{
        flex-direction: column;
    }
    
}
.header{
    grid-area: header;
}
.footer{
    grid-area: footer;
}
.main{
    grid-area: center;
}
</style>