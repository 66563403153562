<template>
  <div :class="getClass" >
        <div class="leftColumn" >
            <label for="" class="labelBold">
                {{topLeft}} 
            </label>
            
        </div>
        <div v-if="showInner">
            <div v-for="result in innerList" :key="result.id" :value="result.id" >
                <div class="horizFlex">
                    <input  type="checkbox"  v-model="result.selected" @change="updateSelection()"/>
                    <label class="entryLabel" for="">{{result.name}}</label>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import lookupresult from '../controls/lookupresult.vue'
export default {
    name:"lookupresult",
    components:{
        lookupresult
    },
    props:{
        topLeft:{
            type:String,
            required:true
        },
        innerList:{
            type:Array,
        },
        showborder:{
            type:Boolean,
            default:false
        },
        
        selected:{
            type:Boolean,
            default:false
        },
        inactive:{
            type:Boolean,
            default:false
        },
        showInner:{
            type:Boolean,
            default:true
        }
        
    },
    data() {
        return {
           
        };
    },
    methods:{
        
        updateSelection(){
            var haveChecks=false;
            this.innerList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let a=arr[index]
                if(a.selected){
                    haveChecks=true
                }
            } 

            //if we are selected, and now we have no checks...emit
            if(this.selected && !haveChecks){
                this.$emit("click")
            }
            //if we are not selected, and now we have a check...emit
            if(!this.selected && haveChecks){
                this.$emit("click")
            }
        }
    },
    computed:{
        getClass(){
            var thisclass='item'
            if(this.inactive){thisclass='inactiveitem'}
            if(this.selected){
                thisclass="selecteditem"
                if(this.inactive){thisclass='inactiveselecteditem'}
            }
            if(this.showborder){
                thisclass= thisclass + " borderedge"
            }
            
            
            
            return thisclass
        }
    }
}
</script>

<style  >
.item{

    background-color: white;
    border-radius:0px;
    margin:5px 0px 5px 0px;
    padding:10px;
    
}
.selecteditem{
    border-style:solid;
    border-color:var(--highlight-color);
    border-width: 3px;
    background-color: white;
    border-radius:0px;
    margin:5px 5px 5px 5px;
    padding:10px;
}

.inactiveitem{

    background-color: gray;
    border-radius:0px;
    margin:5px 0px 5px 0px;
    padding:10px;
}
.inactiveselecteditem{
    border-style:solid;
    border-color:var(--button-color);
    border-width: 4px;
    background-color: gray;
    border-radius:0px;
    margin:5px 0px 5px 0px;
    padding:10px;
}

.borderedge{
    border-style:inset;
    border-color:var(--border-color);
    border-width: .5px;
}
.leftColumn{
    display:flex;
    flex-direction: column;
    text-align: left;
}
.rightColumn{
    display:flex;
    flex-direction: column;
    text-align: right;
}
.labelBold{
  font-size:medium;
  font-weight: bold;
}
.label{
    font-size:medium;
}
.entryLabel{
    width:100px;
}
</style>
