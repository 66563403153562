<template>
<div class='general'>
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form class="mainform" >
                <div class="rightjustified">
                    <smallbutton @click="close()" toplabel='X' buttonclass="buttonclose" />
                </div>
                <label class="titlelabel">{{title}}</label>

               
               <!-- time -->
                <label for="" class="selectorTitle margintop">1. Select Time</label>
                <div class="wrappedresult">
                     <div v-for="result in timeResults" :key="result.id" :value="result.id" >
                        <lookupresult :topLeft="result.name" @click="selectTime(result)"
                            :selected="isSelected_Time(result)">
                        
                        </lookupresult>  
                    </div>
                </div>

                
                <!-- instructor -->
                <label for="" class="selectorTitle margintop">2. Select Instructor</label>
                <div class="wrappedresult">
                     <div v-for="result in instructorResults" :key="result.id" :value="result.id" >
                        <lookupresult :topLeft="result.name" @click="selectInstructor(result)"
                            :selected="isSelected_Instructor(result)">
                        
                        </lookupresult>  
                    </div>
                </div>
               
              
                
                <hr>
              

                <!-- students -->
                 <label for="" class="selectorTitle margintop">3. Select Student(s)</label>
                 <div class="wrappedresult" v-if="showClasses">
                   
                    <div v-for="result in studentResults" :key="result.id" :value="result.id" >
                        <lookupresultstudent :topLeft="result.name" @click="selectStudent(result)"
                            :selected="isSelected(result)" :innerList="result.classList" >
                        
                        </lookupresultstudent>  
                 
                     </div>
                    
                 </div>

                <div class="wrappedresult" v-if="!showClasses">
                   
                    <div v-for="result in studentResults" :key="result.id" :value="result.id" >
                        <lookupresult :topLeft="result.name" @click="selectStudent(result)"
                            :selected="isSelected(result)"  >
                        
                        </lookupresult>  
                 
                     </div>
                    
                 </div>

                <div>
                     <smallbutton  @click="save()" toplabel="Save" />
                    <smallbutton  @click="close()" toplabel="Cancel" />
                </div>
               
              

                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>

            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../services/datamethods'
import format from '../../style/format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from '../controls/actionmodal.vue'
import lookupresult from '../controls/lookupresult.vue'
import lookupresultstudent from './lookupresultstudent.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:'editscheduleitem',
    components:{
        smallbutton,
        actionmodal,
        lookupresult,
        lookupresultstudent
    },
    computed:{
        ...mapFields([
            'studentResults',
            'instructorResults',
            'timeItems',
            'showClasses',
        ]),
        title(){
            return "Edit " + this.selDate + " for " + this.selResource.name
        },
       
        inInstructor(){
            var inst={
                id:-1,
                name:''
            }
            if(this.selItems.length>0){
                inst.id=this.selItems[0].instructorId
                inst.name=this.selItems[0].instructorName
            }
            return inst
        },
        
    },
    props:{
        
        selDate:{
            type:String
        },
        selTime:{
            type:Object
        },
        selResource:{
            type:Object
        },
       timeResults:{
           type:Array
       }
         
    },
    mixins:[datamethods,format],
    data(){
        return{
            message:'',
            pleaseWait:false,

            selItems:null,

            
            selectedInstructor:null,
            selectedTime:this.selTime
        }
    },
    
    methods:{
        close(){
           // this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
       save(){
           //save my stuff//////////////////////////////////////////
           //for now, go through my items... find in all items. replace
           var myitems= this.selItems
           var allitems = this.timeItems
           var selInstructor = this.selectedInstructor
            var founditem=false
            myitems.forEach(myFunction4)
            function myFunction4(item, index, arr) {
                let timeitem=arr[index]
                
                //all items
                
                allitems.forEach(myFunction5)
                function myFunction5(item, index, arr) {
                    let allitem=arr[index]
                    console.log('tid ' + timeitem.id + ' aid ' + allitem.id)
                    if(timeitem.id==allitem.id){
                        founditem=true
                        allitem.instructorId=selInstructor.id
                        allitem.instructorName=selInstructor.name
                    }
                }
                
            }
            if(!founditem){this.addNewItem()}
            ///////////////////////////////////////////
           this.$emit('save')
           this.$refs.mymodal.closeModal()
       },
        addNewItem(){
            var selInstructor = this.selectedInstructor
            var selResource = this.selResource
            var sDate = this.selDate
            var sTime = this.selectedTime.name
            var timeItems = this.timeItems
            var showClasses = this.showClasses
            this.studentResults.forEach(myFunction4)
            function myFunction4(item, index, arr) {
                let student=arr[index]
                if(student.selected){
                    if(showClasses){
                        console.log('t ' + sTime)
                        //find which classes
                        student.classList.forEach(myFunction5)
                        function myFunction5(item, index, arr) {
                            let sclass=arr[index]
                            if(sclass.selected){
                                var newItem={
                                    id:201,
                                    studentId:student.id,
                                    studentName:student.name,
                                    resourceId:selResource.id,
                                    resourceName:selResource.name,
                                    instructorId:selInstructor.id,
                                    instructorName:selInstructor.name,
                                    date:sDate,
                                    time:sTime,
                                    classId:sclass.id,
                                    className:sclass.name
                                }
                                timeItems.push(newItem)
                            }
                        }
                    }
                    else{
                        console.log('t ' + sTime)
                        var newItem={
                            id:201,
                            studentId:student.id,
                            studentName:student.name,
                            resourceId:selResource.id,
                            resourceName:selResource.name,
                            instructorId:selInstructor.id,
                            instructorName:selInstructor.name,
                            date:sDate,
                            time:sTime,
                            classId:-1,
                            className:null
                        }
                        timeItems.push(newItem)
                    }
                    
                }
            }
        },
        openModal(items){
            
            this.selItems=items
            this.selectedInstructor=this.inInstructor
            this.setInStudentSelections()
            this.$refs.mymodal.openModal()
        },
        setInStudentSelections(){
            var myitems = this.selItems;

            //for each student
            this.studentResults.forEach(myFunction2)
            function myFunction2(item, index, arr) {

                //for each class
                let student=arr[index]
                var studenthascheck=false
                student.classList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    let sclass=arr[index]

                    //for each item
                    var foundclass = false
                    myitems.forEach(myFunction4)
                    function myFunction4(item, index, arr) {
                        let timeitem=arr[index]
                        if(timeitem.studentId==student.id && timeitem.classId==sclass.id){
                            foundclass=true
                            studenthascheck=true
                        }
                    }
                    //if we found a match in the item, select the class
                    sclass.selected=foundclass
                
                }
                //if we found any classes in the items, sel the student
                student.selected=studenthascheck
            } 
            this.selItems=myitems
        },
        isSelected(result){
            return result.selected
        },
        isSelected_Instructor(result){
            return result.id==this.selectedInstructor.id
        },
        isSelected_Resource(result){
            return result.selected
        },
        selectStudent(result){
            result.selected=!result.selected
        },
        selectInstructor(result){
            this.selectedInstructor=result
        },
        selectResource(result){
            this.selectedResource=result
        },
        isSelected_Time(result){
            return result.id==this.selectedTime.id
        },
        selectTime(result){
            this.selectedTime=result
        },
    },

}
</script>

<style>
.wrappedresult{
    display: flex;
    flex-wrap: wrap;
}
.mainform{
    width:100%;
    display:grid;
    grid-auto-columns: 1fr;
}
.general{
    font-size:medium;
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.imgbutton{
    background-color:var(--primary-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
  position:relative;
}
.imgbutton:hover{
    background-color:var(--highlight-color);
  cursor:pointer;
}
.icondiv{
    background-color:blue;
    background:transparent;
}
.icon{
    background:transparent;
    max-width: 250px;;
}
.col{
    padding:20px;
    width:100%;
}
.entrycheck{
    text-align: left;
    width:100%;
}
.entrySelect{
    min-width: 220px;
}
form{
    width:100%;
}
.multitext{
    
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
.margintop{
    margin-top:20px;
}
</style>





























