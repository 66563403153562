export default{
    
    data(){
        return{
            //colors
            primaryColor:'#E32323',
            highlightColor:'#F7C142',
            alertColor:'#E32323',
            textColor:'#0E2049',
            successColor:'#699864',
            inactiveColor:'#C3C3C3',
            lightTextColor:'#FFFFFF',

            titleTextColor:'#0E2049',
            mainTextColor:'#0E2049',
            menuTextColor:'#0E2049',
            titleBackgroundColor:'#F7C142',
            mainBackgroundColor:'#FFFFFF',
            borderColor:'#0E2049',
        }
    },
    methods:{
        setColors(){
            this.primaryColor = window.PRIMARY_COLOR
            this.highlightColor = window.HIGHLIGHT_COLOR
            this.alertColor=window.ALERT_COLOR
            this.successColor=window.SUCCESS_COLOR
            this.inactiveColor=window.INACTIVE_COLOR
            this.textColor=window.TEXT_COLOR
            this.lightTextColor=window.LIGHT_TEXT_COLOR
            
        },
        overrideColors(titleText, mainText, menuText,titleBackground,mainBackground){
            this.titleTextColor =titleText
            this.mainTextColor=mainText
            this.menuTextColor=menuText
            this.titleBackgroundColor=titleBackground   
            this.mainBackgroundColor=mainBackground
        },
        overridePrimaryColor(primary){
            this.primaryColor=primary
        },
        overrideHighlightColor(highlight){
            this.highlightColor=highlight
        }
    },
    computed:{
        configStyle(){
            return{
                '--primary-color':this.primaryColor,
                '--highlight-color':this.highlightColor,
                '--alert-color':this.alertColor,
                '--success-color':this.successColor,
                '--inactive-color':this.inactiveColor,
                '--text-color':this.textColor,
                '--light-text-color':this.lightTextColor,

                '--title-text-color':this.titleTextColor,
                '--main-text-color':this.mainTextColor,
                '--menu-text-color':this.menuTextColor,
                '--title-background-color':this.titleBackgroundColor,
                '--main-background-color':this.mainBackgroundColor,
                '--border-color':this.borderColor
            }
        }
    }
    
}