<template>
  <div  ref="resourcediv" :class="resourcemultcolclass">
     
        <div v-for="r in resourceResultsFiltered" :key="r.id" :value="r.id" :class="r.class" @click="edit(r)" >
            <label class="instname">{{myInstructor(r)}}</label>
            <div v-for="i in myItems(r)" :key="i.id" :value="i.id" >
               {{studentText(i)}}
            </div> 
             
        </div>
        <editscheduleitem ref="edititem"  :selDate="selDate" :selTime="selTime" :selResource="selectedResource" @save="save" :timeResults="timeResults"/> 
      
  </div>
</template>

<script>
import editscheduleitem from '../actions/editscheduleitem.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:"schedulebox",
    components:{
        editscheduleitem
    },
    computed:{
        ...mapFields([
            'dateResults',
            'studentResults',
            'instructorResults',
            'resourceResults',
            'timeItems',
            'showClasses'
        ]),
        resourceResultsFiltered(){
            let dt = this.selDate
            let temp = this.resourceResults;
            if(temp!=undefined){
                temp = temp.filter((item)=>{
                    if(dt=='3/16/2022' && item.id==2){
                        return false
                    }else if(dt=='3/19/2022' && item.id!=2){
                        return false
                    
                    }else{return true}
                })
            }
            this.resourcecount=temp.length
            return temp
        },
        resourcemultcolclass(){
            switch (this.resourcecount) {
                case 1:
                    return "resource1col"
                    break;
                case 2:
                    return "resource2col"
                    break;
                case 3:
                    return "resource3col"
                    break;
                case 4:
                    return "resource4col"
                    break;
                default:
                    break;
            }
        }
    },
    props:{
        
        selDate:{
            type:String
        },
        selTime:{
            type:Object
        },
        timeResults:{
            type:Array
        }
    },
    data() {
        return {
           selectedResource:{},
           resourcecount:3
        };
    },
    
    methods:{
        
        isAvailable(r){
            if(this.selDate.date=='3/16/2022' && r.id==2){
                return false
            }else{return true}
        },
        edit(r){
            this.selectedResource=r
            var items = this.myItems(r)
            this.$refs.edititem.openModal(items);
        },
        save(){
            this.$emit('refresh')
        },
        myItems(r){
            let temp = this.timeItems;
            if(temp!=undefined){
                temp = temp.filter((item)=>{
                    return (item.date==this.selDate && item.time==this.selTime.name && item.resourceId==r.id)
                })
            }
            
            return temp
        },
        myInstructor(r){
            var name=''
            let temp = this.timeItems;
            if(temp!=undefined){
                temp = temp.filter((item)=>{
                    return (item.date==this.selDate && item.time==this.selTime.name && item.resourceId==r.id)
                })
            }
            if(temp.length>0){
                name= temp[0].instructorName + " (" + temp[0].time + ")"
            }
            return name
        },
        studentText(i){
            if(this.showClasses){return i.studentName + ' (' + i.className + ')'}
            else{return i.studentName}
        }
    }
}
</script>

<style lang="scss" scoped>
:root{
    --resourcecount:3;
}

.instname{
    font-weight: bold;
}

.resource1col{
    
    height:100%;
    display:grid;
    grid-template-rows: repeat(1,1fr);
}
.resource2col{
    
    height:100%;
    display:grid;
    grid-template-rows: repeat(2,1fr);
}
.resource3col{
    
    height:100%;
    display:grid;
    grid-template-rows: repeat(3,1fr);
}
.resource4col{
    
    height:100%;
    display:grid;
    grid-template-rows: repeat(4,1fr);
}
.resource1{
    border-left: solid blue 3px;
    border-bottom:solid lightgray 1px;
}
.resource2{
    border-left: solid red 3px;
    border-bottom:solid lightgray 1px;
}
.resource3{
    border-left: solid green 3px;
   border-bottom:solid lightgray 1px;
}
.resource4{
    border-left: solid purple 3px;
   border-bottom:solid lightgray 1px;
}
.resource5{
    border-left: solid orange 3px;
   border-bottom:solid lightgray 1px;
}
</style>

<!--  <div class="citem resource2">
                <div class="citem blueresource">
                    <label>Inst: Adam Ant</label>
                    <br>
                    <label>Joe Shmo</label>
                    <br>
                    <label>Jane Doe</label>
                </div>
                <div class="citem redresource">
                    <label>Inst: Adam Ant</label>
                    <br>
                    <label>Joe Shmo</label>
                    <br>
                    <label>Jane Doe</label>
                </div>
                
            </div>
            <div class="citem resource3">
                <div class="citem blueresource">
                    <label>Inst: Adam Ant</label>
                    <br>
                    <label>Joe Shmo</label>
                    <br>
                    <label>Jane Doe</label>
                </div>
                <div class="citem redresource"></div>
                <div class="citem greenresource"></div>
            </div>
            <div class="citem"></div>
            <div class="citem"></div>
            <div class="citem"></div>
            <div class="citem"></div> -->