<template>
  <div class="footerdiv">
     
  </div>
  
</template>

<script>
export default {
    name:'svfooter'
}
</script>

<style>
.footerdiv{
    background-color: var(--primary-color);
    color:var(--light-text-color);
}
</style>